import axios from 'axios';
import CryptoJS from 'crypto-js';

const SECRET_KEY = 'your-secret-key'; // Use environment variables for production

// Encryption function
const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

// Decryption function
const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);

    if (!decryptedText) {
      console.error("Decrypted text is empty");
      return null; // Return null if decryption yields an empty string
    }

    const parsedData = JSON.parse(JSON.parse(decryptedText));
    console.log("Decrypted and parsed data:", parsedData);
    return parsedData; // Return the parsed object
  } catch (error) {
    console.error("Decryption failed:", error);
    return null;
  }
};

const token = window.localStorage.getItem('auth');
const instance = axios.create({
  baseURL: 'https://backenddev.medicappae.com/api',
});

// https://backend.medicappae.com/api

// https://backenddev.medicappae.com/api

// http://localhost:3005/api

if (token) {
  instance.defaults.headers.common['authorization'] = `Bearer ${token}`;
}

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
instance.defaults.headers.common['timezone'] = timezone;
instance.defaults.headers.post['Content-Type'] = 'application/json';

// Request interceptor for encryption
instance.interceptors.request.use(
  (config) => {
    // Skip encryption if Content-Type is multipart/form-data
    if (config.headers['Content-Type'] === 'multipart/form-data') {
      return config;
    }

    // Encrypt data if available and not multipart
    if (config.data) {
      const encryptedData = encryptData(config.data);
      config.data = { payload: encryptedData };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for decryption
instance.interceptors.response.use(
  (response) => {
    if (response.data && response.data.payload) {
      response.data = decryptData(response.data.payload); // Decrypt the response payload if present
    }
    return response;
  },
  (error) => {
    if (error && error.response && error.response.status === 401) {
      window.location.reload(true);
    }
    return Promise.reject(error);
  }
);

export default instance;
